<script>
// Extensions
import View from "@/views/public/View";

// Mixins
import LoadSections from "@/mixins/loadSections";
import getMenu from "@/mixins/getMenu";

export default {
  name: "Menu",

  metaInfo: { title: "Menú" },

  extends: View,

  mixins: [
    getMenu,
    LoadSections(["layout-header-menu", "public-menu", "layout-footer-basic"]),
  ],
};
</script>
